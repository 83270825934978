window.PRONTO =
  $: $
  data: {}
  pickout: null
  init: ->
    $('[data-toggle="tooltip"]').tooltip()
    # initializes selectize, all elements with the class selectize in the aux-form scope
    PRONTO.misc.selectizeByScope('body')
    PRONTO.misc.autonumericByScope('body')
    PRONTO.misc.fixOverflowByScope('body')
    console.log 'made with love by Analisistem'
    return
  payment:
    setup: ->
      $mercadopagoBtn = $('.mercadopago-button')
      $mercadopagoBtn.hide()
      $('#custom_btn').click ->
        $mercadopagoBtn.click()
      $mercadopagoBtn.hide()
  config:
    data: {}
  menu:
    openNav: ->
      document.getElementById('myNav').style.height = '100%'
      return
    closeNav: ->
      document.getElementById('myNav').style.height = '0%'
      return
  calendar:
    data:
      cweek: 1
      year: 2019
      doctorId: null
      specialityId: null
      active: '2019-08-31'
    setup: ->
      @configureArrows()
      @configureDaysOfWeek()
      @setActiveDay()
      return
    configureArrows: ->
      $('.calendar-navigator .week-navigator li.arrow').click ->
        $.ajax
          type: 'GET'
          url: '/calendar/change_week'
          data: {
            calendar: {
              week: PRONTO.calendar.data.cweek,
              year: PRONTO.calendar.data.year,
              active: PRONTO.calendar.data.active,
              step: $(this).data('target')
            }
          }
        return
      return
    configureDaysOfWeek: ->
      $('.calendar-navigator .week-days-container .day-of-week').not('.inactive').click ->
        $.ajax
          type: 'GET'
          url: '/calendar/availabilities_per_day'
          data: {
            calendar: {
              date: $(this).data('date')
              doctor: PRONTO.calendar.data.doctorId,
              speciality: PRONTO.calendar.data.specialityId,
            }
          }
        return
    removeActiveDay: ->
      $(".calendar-navigator .week-days-container .day-of-week").removeClass('active')
      return
    setActiveDay: ->
      $(".calendar-navigator .week-days-container .day-of-week[data-date=#{PRONTO.calendar.data.active}]").addClass('active')
      return
  specialities:
    setup: ->
      @configureSearch()
      @configureSearchSubmit()
      return
    configureSearchSubmit: ->
      $('.footer-bar--search .fa.fa-search').click ->
        PRONTO.specialities.searchSpecialities($('#search_specialities').val())
    configureSearch: ->
      $('#search_specialities').keyup ->
        terms = $(this).val()
        PRONTO.specialities.searchSpecialities(terms)
        return
      return
    searchSpecialities: (terms)->
      $.ajax
        type: 'GET'
        url: '/specialities/search'
        data: {
          search: {
            terms: terms
          }
        }
      return
  doctors:
    setup: ->
      @configureSearch()
      @configureSearchSubmit()
      return
    configureSearchSubmit: ->
      $('.footer-bar--search .fa.fa-search').click ->
        PRONTO.doctors.searchSpecialities($('#search_doctors').val())
    configureSearch: ->
      $('#search_doctors').keyup ->
        terms = $(this).val()
        PRONTO.doctors.searchSpecialities(terms)
        return
      return
    searchSpecialities: (terms)->
      $.ajax
        type: 'GET'
        url: '/doctors/search'
        data: {
          search: {
            terms: terms
            speciality: $('.doctors-container .speciality').data('speciality')
          }
        }
      return
  scheduling:
    data:
      date: (new Date())
    location:
      setup: ->
        $('.interactive-answer').click ->
          $('#form_value').val $(this).data('value')
          $('form.form').submit()
      searching:
        setup: (model, parent)->
          @configureSearch(model, parent)
          @configureSearchSubmit(model, parent)
          return
        configureSearchSubmit: (model, parent)->
          $('.footer-bar--search .fa.fa-search').click ->
            PRONTO.scheduling.professional.searchLocation($("#search_#{model}").val(), parent, model)
        configureSearch: (model, parent)->
          $("#search_#{model}").keyup ->
            terms = $(this).val()
            PRONTO.scheduling.location.searching.searchLocation(terms, parent, model)
            return
          return
        searchLocation: (terms, parent, model)->
          $.ajax
            type: 'GET'
            url: "/location/region/"+model+"/search"
            data: {
              search: {
                terms: terms,
                parent: parent
              }
            }
          return
      indexing:
        setup: (target_name)->
          $(".go-to-#{target_name}-set").click ->
            element = $('#' + $(this).data('target'))
            # rect = element.getBoundingClientRect();
            PRONTO.$(window).scrollTop(element.position().top - 120)
          return
    office:
      setup: ->
        $('.interactive-answer').click ->
          $('#form_value').val $(this).data('value')
          $('form.form').submit()
      searching:
        setup: (parent)->
          @configureSearch(parent)
          @configureSearchSubmit(parent)
          return
        configureSearchSubmit: ->
          $('.footer-bar--search .fa.fa-search').click ->
            PRONTO.scheduling.professional.searchLocation($("#search_offices").val(), parent)
        configureSearch: (parent)->
          $("#search_offices").keyup ->
            terms = $(this).val()
            PRONTO.scheduling.office.searching.searchLocation(terms, parent)
            return
          return
        searchLocation: (terms, parent)->
          $.ajax
            type: 'GET'
            url: "/companies/offices/search"
            data: {
              search: {
                terms: terms,
                parent: parent
              }
            }
          return
      indexing:
        setup: ->
          $(".go-to-office-set").click ->
            element = $('#' + $(this).data('target'))
            # rect = element.getBoundingClientRect();
            PRONTO.$(window).scrollTop(element.position().top - 120)
    agreement:
      setup: ->
        PRONTO.scheduling.submitSimpleForm()
        return
    medicalAgreement:
      data:
        planValue: null
      fetchPlansByEntity: (entity, tenant) ->
        $.ajax(
          type: "GET"
          dataType: "JSON"
          url: "/companies/entities/plans/entity/#{tenant}/provider/#{entity}/plans.json"
        ).done (plans)->
          PRONTO.scheduling.medicalAgreement.clearPlans()
          $.each plans, (index, plan)->
            $('.plans-pickout').append("<option value='#{plan.id.$oid}'>#{plan.plan.name}</option>")
          return
        return
      clearPlans: ->
        PRONTO.scheduling.medicalAgreement.data.planValue = null
        $('.plans-pickout').html('')
        $('.plans-pickout').val('')
        $('.plans-pickout').siblings('.pk-field.-pronto').html('Selecciona un plan')
        $('.plans-pickout').append("<option value></option>")
        return
      setup: ->
        PRONTO.pickout.to
          el: '.entities-pickout'
          search: false
          theme: 'pronto'
          callback: (option) ->
            if option
              PRONTO.scheduling.medicalAgreement.fetchPlansByEntity(option.value, $('#form_current_tenant').val())
            else
              PRONTO.scheduling.medicalAgreement.clearPlans()
            return
        PRONTO.pickout.to
          el: '.plans-pickout'
          search: false
          theme: 'pronto'
          callback: (option) ->
            # trick for safari and apple
            # PRONTO.misc.d $('.plans-pickout').val() doesn't work
            # PRONTO.misc.d $('.plans-pickout').children("option:selected").val() doesn't work
            # document.getElementById("form_value").value doesn't work
            # and so on...
            PRONTO.scheduling.medicalAgreement.data.planValue = option.value
            return
        $('.form-container .btn-next').click ->
          PRONTO.misc.d PRONTO.scheduling.medicalAgreement.data.planValue, 'PLANS CLICK CALLBACK'
          if PRONTO.scheduling.medicalAgreement.data.planValue
            $('form.form').submit()
          else
            Swal.fire(
              icon: 'warning',
              title: 'Plan de salud no seleccionado',
              text: 'Por favor seleccione un plan de salud antes de continuar',
              confirmButtonText: 'Aceptar'
            )
          return
        return
    selectionType:
      setup: ->
        PRONTO.scheduling.submitSimpleForm()
        return
    appointmentType:
      setup: ->
        PRONTO.scheduling.submitSimpleForm()
        return
    speciality:
      setup: ->
        @configureSearch()
        @configureSearchSubmit()
        PRONTO.scheduling.submitSimpleForm()
        return
      configureSearchSubmit: ->
        $('.footer-bar--search .fa.fa-search').click ->
          PRONTO.scheduling.speciality.searchSpecialities($('#search_specialities').val())
      configureSearch: ->
        $('#search_specialities').keyup ->
          terms = $(this).val()
          PRONTO.scheduling.speciality.searchSpecialities(terms)
          return
        return
      searchSpecialities: (terms)->
        $.ajax
          type: 'GET'
          url: '/specialities/search'
          data: {
            search: {
              terms: terms
            }
          }
        return
      # TODO refactor code below
      indexing:
        setup: ->
          $('.go-to-speciality-set').click ->
            element = $('#' + $(this).data('target'))
            # rect = element.getBoundingClientRect();
            PRONTO.$(window).scrollTop(element.position().top - 120)
          return
    professional:
      setup: ->
        @configureSearch()
        @configureSearchSubmit()
        PRONTO.scheduling.submitSimpleForm()
        return
      configureSearchSubmit: ->
        $('.footer-bar--search .fa.fa-search').click ->
          PRONTO.scheduling.professional.searchSpecialities($('#search_doctors').val())
      configureSearch: ->
        $('#search_doctors').keyup ->
          terms = $(this).val()
          PRONTO.scheduling.professional.searchSpecialities(terms)
          return
        return
      searchSpecialities: (terms)->
        $.ajax
          type: 'GET'
          url: '/doctors/search'
          data: {
            search: {
              terms: terms
            }
          }
        return
      # TODO refactor code below
      indexing:
        setup: ->
          $('.go-to-doctor-set').click ->
            element = $('#' + $(this).data('target'))
            # rect = element.getBoundingClientRect();
            PRONTO.$(window).scrollTop(element.position().top - 120)
          return
    luckyProfessional:
      setup: ->
        @configureSearch()
        @configureSearchSubmit()
        PRONTO.scheduling.submitSimpleForm()
        return
      configureSearchSubmit: ->
        $('.footer-bar--search .fa.fa-search').click ->
          PRONTO.scheduling.luckyProfessional.searchSpecialities($('#search_doctors').val())
      configureSearch: ->
        $('#search_doctors').keyup ->
          terms = $(this).val()
          PRONTO.scheduling.luckyProfessional.searchSpecialities(terms)
          return
        return
      searchSpecialities: (terms)->
        $.ajax
          type: 'GET'
          url: '/doctors/search'
          data: {
            search: {
              terms: terms
            }
          }
        return
    specialityByProfessional:
      setup: ->
        @configureSearch()
        @configureSearchSubmit()
        PRONTO.scheduling.submitSimpleForm()
        return
      configureSearchSubmit: ->
        $('.footer-bar--search .fa.fa-search').click ->
          PRONTO.scheduling.specialityByProfessional.searchSpecialities($('#search_specialities').val())
      configureSearch: ->
        $('#search_specialities').keyup ->
          terms = $(this).val()
          PRONTO.scheduling.specialityByProfessional.searchSpecialities(terms)
          return
        return
      searchSpecialities: (terms)->
        $.ajax
          type: 'GET'
          url: '/specialities/search'
          data: {
            search: {
              terms: terms
            }
          }
        return
      # TODO refactor code below
      indexing:
        setup: ->
          $('.go-to-speciality-set').click ->
            element = $('#' + $(this).data('target'))
            # rect = element.getBoundingClientRect();
            PRONTO.$(window).scrollTop(element.position().top - 120)
    appointment:
      data:
        cweek: 1
        year: 2019
        doctorId: null
        specialityId: null
        active: '2019-08-31'
      setup: ->
        @configureArrows()
        @configureDaysOfWeek()
        @setActiveDay()
        PRONTO.scheduling.submitSimpleForm()
        return
      configureArrows: ->
        $('.calendar-navigator .week-navigator li.arrow').click ->
          $.ajax
            type: 'GET'
            url: '/calendar/change_week'
            data: {
              calendar: {
                week: PRONTO.scheduling.appointment.data.cweek,
                year: PRONTO.scheduling.appointment.data.year,
                active: PRONTO.scheduling.appointment.data.active,
                step: $(this).data('target')
              }
            }
          return
        return
      configureDaysOfWeek: ->
        $('.calendar-navigator .week-days-container .day-of-week').not('.inactive').click ->
          $.ajax
            type: 'GET'
            url: '/calendar/availabilities_per_day'
            data: {
              calendar: {
                date: $(this).data('date')
                doctor: PRONTO.scheduling.appointment.data.doctorId,
                speciality: PRONTO.scheduling.appointment.data.specialityId,
              }
            }
          return
      removeActiveDay: ->
        $(".calendar-navigator .week-days-container .day-of-week").removeClass('active')
        return
      setActiveDay: ->
        $(".calendar-navigator .week-days-container .day-of-week[data-date=#{PRONTO.scheduling.appointment.data.active}]").addClass('active')
        return
    personalData:
      setup: ->
        PRONTO.scheduling.personalData.submitForm()
        return
      submitForm: (defaultInput = true) ->
        $('.steps-container .btn-next').click ->
          if PRONTO.scheduling.personalData.validateInputs()
            $("#edit_form").val($(this).data('target'))
            Swal.fire(
              title: '¿Está seguro?'
              text: 'Confirmación de datos personales'
              type: 'warning'
              showCancelButton: true
              confirmButtonText: 'Si'
              cancelButtonText: 'Cancelar'
              customClass:
                confirmButton: 'btn swal-btn-confirm'
                cancelButton: 'btn swal-btn-cancel'
            ).then (result) ->
              if result.value
                $("#edit_form").submit()
              return
            return
          else
            Swal.fire(
              title: 'Campos vacios'
              text: 'Por favor complete todos los campos'
              type: 'warning'
              confirmButtonText: 'Entendido'
              customClass:
                confirmButton: 'btn swal-btn-confirm'
            )
        return
      validateInputs: ->
        fields = ['document_identification','first_name','last_name','cellphone']
        for name in fields
          return false unless !!($("#form_profile_attributes_#{name}").val())
        return true
        return
    confirmation:
      setup: ->
        PRONTO.scheduling.confirmation.submitForm()
        PRONTO.scheduling.confirmation.acceptConsent()
        return
      acceptConsent: ->
        $('#accept-consent').click ->
          $('.consent-modal').removeClass('modal-on');
          $('#form_consent_accepted').val(true)
          $('#consent-btn').addClass('hidden');
          $('#confirmation-btn').removeClass('hidden');
      submitForm: (defaultInput = true) ->
        $('.steps-container .btn-next').click ->
          fieldName = $(this).data('label') || 'value'
          $("#form_#{fieldName}").val($(this).data('target'))
          Swal.fire(
            title: '¿Está seguro?'
            text: 'Confirmación de Cita'
            type: 'warning'
            showCancelButton: true
            confirmButtonText: 'Si'
            cancelButtonText: 'Cancelar'
            customClass:
              confirmButton: 'btn swal-btn-confirm'
              cancelButton: 'btn swal-btn-cancel'
          ).then (result) ->
            if result.value
              $('form.form').submit()
            return
          return
        return
    setup: ->
      # Nothing for now
      # console.log(PRONTO.scheduling.data.date)
      # window.addEventListener 'load', ->
      #   # window.history.replaceState {}, '', '/scheduling/steps/current'
      #   history.pushState(null, null, '/scheduling/steps/current');
      #   window.location.onPopState = ->
      #     history.pushState(null, null, '/scheduling/steps/current')
      #   console.log window.location.href
      return
      # window.addEventListener 'load', ->
      #   beforeLength = window.history.length + ''
      #   beforelocation = window.location + ''
      #   # window.history.replaceState {}, '', '/scheduling/steps/prev'
      #   # document.location.replace '/scheduling/steps/current'
      #   alert "LOAD > #{document.referrer} #{beforeLength} #{beforelocation} | #{window.history.length} #{window.location}"
      #   return
      # window.addEventListener 'popstate', ->
      #   beforeLength = window.history.length
      #   beforelocation = window.location
      #   # document.location.replace '/scheduling/steps/current'
      #   # window.history.pushState {}, '', '/scheduling/steps/next'
      #   alert "POP > #{beforeLength} #{beforelocation} | #{window.history.length} #{window.location}"
      #   return
      # window.addEventListener 'popstate', ((event) ->
      #   # The popstate event is fired each time when the current history entry changes.
      #   r = confirm('You pressed a Back button! Are you sure?!')
      #   if r == true
      #     # Call Back button programmatically as per user confirmation.
      #     window.history.go(-6)
      #     # Uncomment below line to redirect to the previous page instead.
      #     # window.location = document.referrer // Note: IE11 is not supporting this.
      #     # else
      #     # Stay on the current page.
      #     # window.history.pushState null, null, window.location.pathname
      #
      #   # history.pushState null, null, window.location.pathname
      #   return
      # ), false
      # return
    submitSimpleForm: (defaultInput = true) ->
      $('.steps-container .btn-next').click ->
        fieldName = $(this).data('label') || 'value'
        $("#form_#{fieldName}").val($(this).data('target'))
        $('form.form').submit()
        return
      return
  users:
    appointments:
      setup: ->
        $fullCalendar = $('.full-calendar')
        # eventsUrl = "/users/profile/appointments.json"
        eventsUrl = ""
        $fullCalendar.fullCalendar PRONTO.users.appointments.fullcalendarConfig(eventsUrl)
        return
      fullcalendarConfig: (eventsUrl)->
        {
          selectable: false
          selectHelper: false
          week:
            timeFormat: 'h:mm'
          events: eventsUrl
          eventConstraint: 'businessHours'
          header: { center: $('.full-calendar').data('header'), right: 'today prev,next' }
          buttonText:
            listWeek: '[L] Semana',
            listDay: '[L] Día'
            agendaWeek: '[A] Semana'
            agendaDay: '[A] Día'
          defaultTimedEventDuration: '00:30:00'
          firstDay: 1
          scrollTime: '09:00:00'
          defaultView: 'listDay'
          height: 'auto'
          slotLabelFormat: "HH:mm"
          themeSystem: 'bootstrap4'
          allDaySlot: false
          nowIndicator: false
          columnFormat: 'dddd DD'
          locale: "es"
          minTime: "06:00:00"
          maxTime: "20:00:00"
          businessHours:
            start: '07:00'
            end: '18:30'
            dow: [ 1, 2, 3, 4, 5, 6 ]
          eventClick: (calEvent, jsEvent, view) ->
            $.ajax
              type: "GET"
              url: "/admin/appointment_books/#{calEvent.id}"
            return
          eventAfterAllRender: () ->
            # TODO figure out why I have to do below trick for rendering the events
            # perhaps you need to disable the turbolinks
            # $appointmentsBook = $('.full-calendar')
            # unless PRONTO.admin.appointmentBooks.slots.data.reRendered
            #   PRONTO.admin.appointmentBooks.slots.data.reRendered = true
            #   $appointmentsBook.fullCalendar('changeView', 'listDay');
        }
    signUp:
      setup: ->
        PRONTO.pickout.to
          el: '.identification-pickout'
          search: false
          theme: 'pronto'
          callback: (option)->
            if option
              $('.identification-pickout').parent().removeClass('form-group-invalid')
  misc:
    autonumericByScope: (scope) ->
      $(scope).find('input.autonumeric').not('autonumerized').each (index, element) ->
        $(this).addClass 'autonumerized'
        new AutoNumeric(element, PRONTO.config.data.autonumeric)
        return
      return
    d: (object, location = 'NONE', alert = false) ->
      $.ajax
        type: "POST"
        url: "/debugger/andromeda/call"
        data: { debugger: { andromeda:  ('' + object), triangulum: location } }
      alert(object) if alert
      return
    # computes a random code taking into account the time and a 3 digit random code
    getRandomCode: ()->
      "#{new Date().getTime()}#{Math.floor((Math.random() * 1000) + 1);}"

    flatpickrByScope: (scope)->
      $(scope).find('.flatpickr-date').not('flatpickred').each (index)->
        flatpickr $(this)[0]
        $(this).addClass 'flatpickred'
        return
      return
    #
    # selectizes all select with class 'selectize' in the sepcific html scope, it will ignore the element who have
    # been selectized
    #
    # @param scope [ String, Jquery Object ]
    #
    fixOverflowByScope: (scope)->
      $(scope).find('.dropdown-fix').each (index)->
        $(this).on 'show.bs.dropdown', ->
          $(this).css 'overflow', 'inherit'
          return
        $(this).on 'hide.bs.dropdown', ->
          $(this).css 'overflow', 'auto'
          return
    selectizeByScope: (scope)->
      $(scope).find('select.selectize').not('selectized').each (index)->
        $(this).selectize
          sortField: 'text'
        $(this).addClass 'selectized'
        return
      return
    customInputFileByScope: (scope)->
      $(scope).find('.custom-file').each (index)->
        $current_container = $(this)
        $current_container.find('.custom-file-input').first().on 'change', ->
            fileName = if $(this).val().split('\\').pop() then $(this).val().split('\\').pop() else 'Seleccione un archivo'
            $current_container.find('.custom-file-label').first().addClass('selected').html fileName
        return
      return
    setCookie: (cname, cvalue, exdays) ->
      d = new Date
      d.setTime d.getTime() + exdays * 24 * 60 * 60 * 1000
      expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
      return
    getCookie: (cname) ->
      name = cname + '='
      decodedCookie = decodeURIComponent(document.cookie)
      ca = decodedCookie.split(';')
      i = 0
      while i < ca.length
        c = ca[i]
        while c.charAt(0) == ' '
          c = c.substring(1)
        if c.indexOf(name) == 0
          return c.substring(name.length, c.length)
        i++
      ''
  clearData: ->
    PRONTO.data = {}
    console.log 'DATA cleared'
  selfManagement:
    # teleconsultingConsent:
      # setup: (id)->
      #   $.ajax(
      #     type: "get"
      #     url: "/appointments/teleconsulting/#{id}/consent/show"
      #   )
    simpleSurvey:
      setup: ->
        $('.input-rate').click (event)->
          input_value = $(this).val()
          setTimeout (->
            $('#surveys_simple_record_value').val input_value
            $('#new_surveys_simple_record').submit()
            return
          ), 1000
    npsSurvey:
      setup: ->
        $('button').click (event)->
          event.preventDefault()
          $this = $(this)
          $prevAll = $(this).prevAll()
          $('button').removeClass 'detractor-hover passive-hover promoter-hover'
          className = $this.attr('class') + '-hover'
          $this.addClass className
          $prevAll.addClass className
          $('#surveys_nps_record_value').val $(this).data('value')
        $('#submit').click (event)->
          if $('#surveys_nps_record_value').val()
            $('#new_surveys_nps_record').submit()
          else
            Swal.fire(
              icon: 'warning',
              title: 'No hay calificación',
              text: 'Por favor responda la pregunta dando click en alguno de los números',
              confirmButtonText: 'Aceptar'
            )
    covidSurvey:
      setup: (patient)->
        unless patient == 'true'
          $('.navbar-text').addClass('hidden')
        $('#smartmobilewizard').smartWizard({
          theme: 'dots',
          transition: {
            animation: 'fade'
          },
          toolbarSettings: {
            toolbarPosition: 'none',
            showNextButton: false,
            showPreviousButton: false,
          },
          enableURLhash: false,
        })
        $('#smartwizard').smartWizard({
          theme: 'progress',
          transition: {
            animation: 'fade'
          },
          toolbarSettings: {
            toolbarPosition: 'none',
            showNextButton: false,
            showPreviousButton: false,
          },
          enableURLhash: false,
        })
        $('.option').click ->
          $nextButton = $(this).parent().parent().parent().find('.next-button')
          $nextButton.removeClass('disabled')
        $('.next-button').click ->
          step = $(this).parent().parent().attr('id')
          unless step == 'step-6'
            if parseInt(step[5]) <= ($('.option:checked').length - 1)
              $('.wizard-container').smartWizard('next')
              $(this).parent().addClass('hidden')
              PRONTO.selfManagement.covidSurvey.switchBtnContainer($(this).data('next'),$(this).parent())
              $('.next-button').last().html('Enviar') if step == 'step-5'
          else if step == "step-#{$('.option:checked').length - 1}"
            $('#new_surveys_covid_colombia').submit()
        $('.previus-button').click ->
          unless $(this).parent().parent().attr('id') == 'step-0'
            $('.wizard-container').smartWizard('prev')
            PRONTO.selfManagement.covidSurvey.switchBtnContainer($(this).data('prev'),$(this).parent())
      switchBtnContainer: (target,parent)->
        parent.addClass('hidden')
        $("#step-#{target}").find('.btn-container').removeClass('hidden')
    payment:
      payu:
        setup: ->
          $('#payu_btn').click ->
            $.ajax(
              type: "PATCH"
              url: "/payment/appointments/statuses/payu/#{$(this).data('target')}/click"
            )
        update_info: ->
          $.ajax(
            type: "PATCH"
            url: "/payment/appointments/statuses/payu/#{$('#processing_btn').data('target')}/processing"
          )
      mercadoPago:
        setup: ->
          $mercadopagoBtn = $('.mercadopago-button')
          $mercadopagoBtn.hide()
          $('#custom_btn').click ->
            $mercadopagoBtn.click()
          $mercadopagoBtn.hide()
    appointment:
      documents:
        setup: ->
          # add custom input file behavior, all elements with the class custom-file
          PRONTO.misc.customInputFileByScope('body')
$(document).on 'turbolinks:load', ->
  PRONTO.init()
