// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Refactor all code below
// require("@rails/ujs").start()
// import Rails from 'rails-ujs';
// import 'sweetalert2'
const Rails = require("@rails/ujs")
import Swal from 'sweetalert2'

const handleConfirm = function(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

const allowAction = element => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true
  }

  showConfirmationDialog(element)
  return false
}

// Display the confirmation dialog
const showConfirmationDialog = element => {
  const message = element.getAttribute('data-confirm-swal')
  const text = element.getAttribute('data-text')

  Swal.fire({
    title: message || 'Are you sure?',
    text: text || '',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Si',
    cancelButtonText: 'Cancelar',
    customClass: {
      confirmButton: 'btn swal-btn-confirm',
      cancelButton: 'btn swal-btn-cancel',
    }
  }).then(result => confirmed(element, result))
}

const confirmed = (element, result) => {
  if (result.value) {
    // User clicked confirm button
    element.removeAttribute('data-confirm-swal')
    element.click()
  }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm)
Rails.start()

require("turbolinks").start()
require("channels")
require("jquery")
require("src/javascripts/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import $ from 'jquery';
import '../src/javascripts/service_worker'
import 'bootstrap'
import "../src/stylesheets/application"
import "../src/javascripts/main"
import 'selectize'
import '../../../node_modules/smartwizard/dist/js/jquery.smartWizard.js'
import '../../../node_modules/smartwizard/dist/css/smart_wizard_all.css'
// import 'pickout'
import Pickout from "../src/javascripts/pickout"
window.PRONTO.pickout = Pickout
import '../../../node_modules/pickout/dist/pickout.min.css';
import 'fullcalendar'
import '../../../node_modules/fullcalendar/dist/fullcalendar.css';
// import 'starability'
import '../../../node_modules/starability/starability-css/starability-heartbeat.css';
import '../../../node_modules/fullcalendar/dist/locale-all.js';
